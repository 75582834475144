import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/lang"; // Internationalization
import { layoutBanner } from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "router.index",
    meta: {
      key: "index",
    },
    component: () => import("@/views/index.vue"),
  },
  {
    // 景区介绍
    path: "/introduction",
    component: () => import("@/views/layout.vue"),
    name: "router.introduction.index",
    meta: {
      layoutBanner: require("@/assets/images/introduction/banner.jpg"),
    },
    redirect: "/introduction/list",
    children: [
      {
        path: "list",
        name: "router.introduction.list",
        meta: {
          key: "introduction",
        },
        component: () => import("@/views/introduction/list"),
      },
      {
        path: "detail",
        name: "router.introduction.detail",
        meta: {
          key: "introduction",
          parentPath: "/introduction/list",
        },
        component: () => import("@/views/introduction/detail"),
      },
    ],
  },
  {
    // 在线预定
    path: "/reserve",
    component: () => import("@/views/layout.vue"),
    name: "router.reserve.index",
    redirect: "/reserve/list",
    meta: {
      layoutBanner: require("@/assets/images/reservel/banner.jpg"),
    },
    children: [
      {
        path: "list",
        name: "router.reserve.list",
        meta: {
          key: "reserve",
        },
        component: () => import("@/views/reserve/list"),
      },
    ],
  },
  {
    // 景区资讯
    path: "/information",
    component: () => import("@/views/layout.vue"),
    name: "router.information.index",
    redirect: "/information/list",
    meta: {
      layoutBanner: require("@/assets/images/information/banner.jpg"),
    },
    children: [
      {
        path: "list",
        name: "router.information.list",
        meta: {
          key: "information",
        },
        component: () => import("@/views/information/list"),
      },
      {
        path: "detail",
        name: "router.information.detail",
        meta: {
          key: "information",
        },
        component: () => import("@/views/detail"),
      },
    ],
  },
  {
    // 活动预告
    path: "/activity",
    component: () => import("@/views/layout.vue"),
    name: "router.activity.index",
    redirect: "/activity/list",
    meta: {
      layoutBanner: require("@/assets/images/activity/banner.jpg"),
    },
    children: [
      {
        path: "list",
        name: "router.activity.list",
        meta: {
          key: "activity",
        },
        component: () => import("@/views/activity/list"),
      },
      {
        path: "detail",
        name: "router.activity.detail",
        meta: {
          key: "activity",
        },
        component: () => import("@/views/detail"),
      },
    ],
  },
  {
    // 游玩攻略
    path: "/strategy",
    component: () => import("@/views/layout.vue"),
    name: "router.strategy.index",
    redirect: "/strategy/traffic-list",
    meta: {
      layoutBanner: require("@/assets/images/strategy/banner.jpg"),
    },
    children: [
      {
        path: "traffic-list",
        name: "router.strategy.trafficList",
        meta: {
          key: "strategy",
        },
        component: () => import("@/views/strategy/traffic-list.vue"),
      },
      {
        path: "accommodation-list",
        name: "router.strategy.accommodationList",
        meta: {
          key: "strategy",
        },
        component: () => import("@/views/strategy/accommodation-list.vue"),
      },
      {
        path: "detail",
        name: "router.strategy.detail",
        meta: {
          key: "strategy",
        },
        component: () => import("@/views/detail"),
      },
    ],
  },
  {
    // 在线咨询
    path: "/consult",
    component: () => import("@/views/layout.vue"),
    name: "router.consult.index",
    redirect: "/consult/form",
    meta: {
      layoutBanner: require("@/assets/images/consult/banner.jpg"),
    },
    children: [
      {
        path: "form",
        name: "router.consult.form",
        component: () => import("@/views/consult/form.vue"),
      },
      {
        path: "detail",
        name: "router.consult.detail",
        component: () => import("@/views/consult/detail.vue"),
      },
    ],
  },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, form, next) => {
  const language = i18n.messages[i18n.locale];
  const str = "language." + to.name;
  const defaultTitle =
    i18n.messages[i18n.locale].index.firstTitle || "江郎山·廿八都旅游区";
  document.title = defaultTitle + " " + eval(str);
  if (to.matched.length) {
    let bannerImage = to.matched[0].meta.layoutBanner;
    layoutBanner.set_image(bannerImage);
  }
  next();
});
export default router;
