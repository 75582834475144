import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./style/index.css"; //默认样式
import i18n from "./lang"; // Internationalization

Vue.config.productionTip = false;
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value),
});
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
