import Vue from "vue";
import VueI18n from "vue-i18n";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang 简体中文
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang 英语
import elementJaLocale from "element-ui/lib/locale/lang/ja"; // element-ui lang 日语
import elementKoLocale from "element-ui/lib/locale/lang/ko"; // element-ui lang 韩语

import zhLocale from "./zh";
import enLocale from "./en";
import jaLocale from "./ja";
import koLocale from "./ko";
import { getStore } from "@/utils/store";
Vue.use(VueI18n);

const messages = {
  zh: {
    ...elementZhLocale,
    ...zhLocale,
  },
  en: {
    ...elementEnLocale,
    ...enLocale,
  },
  ja: {
    ...elementJaLocale,
    ...jaLocale,
  },
  ko: {
    ...elementKoLocale,
    ...koLocale,
  },
};

const i18n = new VueI18n({
  locale: getStore({ name: "language" }) || "zh",
  messages,
});

export default i18n;
