import { reactive } from "vue";

export const categoryTree = reactive({
  category: [],
  set_category(arr) {
    this.category = arr;
  },
});

export const layoutBanner = reactive({
  backgroundImage: "",
  set_image(str) {
    this.backgroundImage = str;
  },
});
